<template>
    <v-container fluid id="container-acabana" v-if="edicao.id">
        <v-row>
            <v-col>
                <img id="banner" :src="banner" alt="">
                <h1>{{ edicao.cidade }}/{{ edicao.estado }}</h1>
                <h3>{{ dataEscrita }}</h3>
            </v-col>
        </v-row>
        <v-row v-if="etapa == 1">
            <v-col cols="12" md="6" offset-md="3">
                <v-row>
                    <v-col>
                        <label for="">Nome Completo</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: João Gabriel Silva" v-model="nome">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Sexo</label><br>
                        <select class="input-acabana" v-model="sexo">
                            <option value="" disabled selected>Selecione</option>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                        </select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Estado</label><br>
                        <select class="input-acabana" v-model="estado">
                            <option value="" disabled selected>Selecione</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Cidade</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: São Paulo" v-model="cidade">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Data de Nascimento</label><br>
                        <input class="input-acabana" type="date" v-model="nascimento">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Igreja</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: Igreja Batista" v-model="igreja">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Pastor</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: Pastor Lucas" v-model="pastor"> 
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Funcao</label><br>
                        <select class="input-acabana" v-model="funcao">
                            <option value="" disabled selected>Selecione</option>
                            <option value="Apostolo">Apóstolo</option>
                            <option value="Bispo">Bispo</option>
                            <option value="Pastor">Pastor</option>
                            <option value="Pastora">Pastora</option>
                            <option value="Evangelista">Evangelista</option>
                            <option value="Presbitero">Presbítero</option>
                            <option value="Presbitera">Presbítera</option>
                            <option value="Missionario">Missionário</option>
                            <option value="Missionaria">Missionária</option>
                            <option value="Diacono">Diácono</option>
                            <option value="Diaconisa">Diaconisa</option>
                            <option value="Nenhuma">Nenhuma função</option>
                            <option value="Outra">Outra função</option>
                        </select>
                    </v-col>
                </v-row>
                <v-row v-if="selecionouOutra">
                    <v-col>
                        <label for="">Qual função?</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: Ministro" v-model="funcaoOutra">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">RG</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: 123456789" v-model="rg">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Orgão Expedidor</label><br>
                        <select class="input-acabana" v-model="orgao_expedidor">
                            <option value="" disabled selected>Selecione</option>
                            <option value="SSP">SSP - Secretaria de Segurança Pública</option>
                            <option value="PM">PM - Polícia Militar</option>
                            <option value="PC">PC - Polícia Civil</option>
                            <option value="CNT">CNT - Carteira Nacional de Habilitação</option>
                            <option value="DIC">DIC - Diretoria de Identificação Civil</option>
                            <option value="CTPS">CTPS - Carteira de Trabalho e Previdência Social</option>
                            <option value="FGTS">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                            <option value="IFP">IFP - Instituto Félix Pacheco</option>
                            <option value="IPF">IPF - Instituto Pereira Faustino</option>
                            <option value="IML">IML - Instituto Médico-Legal</option>
                            <option value="MTE">MTE - Ministério do Trabalho e Emprego</option>
                            <option value="MMA">MMA - Ministério da Marinha</option>
                            <option value="MAE">MAE - Ministério da Aeronáutica</option>
                            <option value="MEX">MEX - Ministério do Exército</option>
                            <option value="POF">POF - Polícia Federal</option>
                            <option value="POM">POM - Polícia Militar</option>
                            <option value="SES">SES - Carteira de Estrangeiro</option>
                            <option value="SJS">SJS - Secretaria da Justiça e Segurança</option>
                            <option value="SJTS">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                            <option value="ZZZ">Outros (inclusive exterior)</option>
                        </select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">WhatsApp</label><br>
                        <input class="input-acabana" type="text" placeholder="Ex.: (11) 99999-9999" v-model="whatsapp">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Email</label><br>
                        <input class="input-acabana" type="email" placeholder="Ex.: joao@gmail.com" v-model="email">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <label for="">Tamanho Camiseta</label><br>
                        <select class="input-acabana" v-model="tamanho_camiseta">
                            <option value="" disabled selected>Selecione</option>
                            <option value="NORMAL PP (MASCULINA)">NORMAL PP (MASCULINA)</option>
                            <option value="NORMAL P (MASCULINA)">NORMAL P (MASCULINA)</option>
                            <option value="NORMAL M (MASCULINA)">NORMAL M (MASCULINA)</option>
                            <option value="NORMAL G (MASCULINA)">NORMAL G (MASCULINA)</option>
                            <option value="NORMAL GG (MASCULINA)">NORMAL GG (MASCULINA)</option>
                            <option value="NORMAL EXG (MASCULINA)">NORMAL EXG (MASCULINA)</option>
                            <option value="BABYLOOK PP (FEMININA)">BABYLOOK PP (FEMININA)</option>
                            <option value="BABYLOOK P (FEMININA)">BABYLOOK P (FEMININA)</option>
                            <option value="BABYLOOK M (FEMININA)">BABYLOOK M (FEMININA)</option>
                            <option value="BABYLOOK G (FEMININA)">BABYLOOK G (FEMININA)</option>
                            <option value="BABYLOOK GG (FEMININA)">BABYLOOK GG (FEMININA)</option>
                            <option value="BABYLOOK EXG (FEMININA)">BABYLOOK EXG (FEMININA)</option>
                        </select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="declaracao1">
                        <input type="checkbox" id="declaracao1" v-model="declaracao1">
                        <label for="declaracao1">Declaro estar ciente que não podem participar da Cabana novos convertidos, grávidas, pessoas com mobilidade reduzida e pessoas que estão fazendo ou fizeram recentemente tratamento psicológico ou psiquiátrico.</label>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="declaracao2">
                        <input type="checkbox" id="declaracao2" v-model="declaracao2">
                        <label for="declaracao2">Declaro estar ciente que a CABANA é um programa de treinamento que envolve pressão física, emocional e psicológica. Que tenho consciência que não é um acampamento padrão e que tenho a benção da minha liderança para participar do programa.</label>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col>
                        <button class="button-acabana" @click="continuar" :disabled="desabilitaBotao">CONTINUAR</button>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row v-if="etapa == 2">
            <v-col cols="12">
                <h3>Confirme seus dados</h3>
            </v-col>
            <v-col cols="12" class="dados">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">
                        <v-card class="card-cabana">
                            <v-card-title>
                                <h4>Dados do Participante</h4>
                            </v-card-title>
                            <v-card-text>
                                <table>
                                    <tr>
                                        <td>Nome:</td>
                                        <td>{{ participante.nome }}</td>
                                    </tr>
                                    <tr>
                                        <td>Sexo:</td>
                                        <td>{{ participante.sexo == 'M' ? 'Masculino' : 'Feminino' }}</td>
                                    </tr>
                                    <tr>
                                        <td>Estado:</td>
                                        <td>{{ participante.estado }}</td>
                                    </tr>
                                    <tr>
                                        <td>Cidade:</td>
                                        <td>{{ participante.cidade }}</td>
                                    </tr>
                                    <tr>
                                        <td>Data de Nascimento:</td>
                                        <td>{{ participante.nascimento }}</td>
                                    </tr>
                                    <tr>
                                        <td>Igreja:</td>
                                        <td>{{ participante.igreja }}</td>
                                    </tr>
                                    <tr>
                                        <td>Pastor:</td>
                                        <td>{{ participante.pastor }}</td>
                                    </tr>
                                    <tr>
                                        <td>Função:</td>
                                        <td>{{ participante.funcao == "Outra" ? participante.funcaoOutra : participante.funcao }}</td>
                                    </tr>
                                    <tr>
                                        <td>RG:</td>
                                        <td>{{ participante.rg }}</td>
                                    </tr>
                                    <tr>
                                        <td>Orgão Expedidor:</td>
                                        <td>{{ participante.orgao_expedidor }}</td>
                                    </tr>
                                    <tr>
                                        <td>WhatsApp:</td>
                                        <td>{{ participante.whatsapp }}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{{ participante.email }}</td>
                                    </tr>
                                    <tr>
                                        <td>Tamanho Camiseta:</td>
                                        <td>{{ participante.tamanho_camiseta }}</td>
                                    </tr>
                                </table>
                            </v-card-text>
                        </v-card>
                        <v-row>
                            <v-col>
                                <button class="button-link" @click="corrigir" :disabled="desabilitaBotao">CORRIGIR</button>
                            </v-col>
                            <v-col>
                                <button class="button-acabana" @click="enviaApi" :disabled="desabilitaBotao">CONFIRMAR</button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            
        </v-row>
        <v-row v-if="etapa == 3">
            <v-col cols="12">
                <h3>Pagamento</h3>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">
                        <v-card class="card-cabana">  
                            <v-card-title>
                                <h4>Pagamento</h4>
                            </v-card-title>
                            <v-card-text>
                                <table>
                                    <tr>
                                        <td>Nome:</td>
                                        <td>{{ participante.nome }}</td>
                                    </tr>
                                    <tr>
                                        <td>Telefone:</td>
                                        <td>{{ participante.whatsapp }}</td>
                                    </tr>
                                </table>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="6" offset-md="3">
                        <v-row v-if="!geradoPagamento">
                            <v-col cols="12">
                                <h4 style="text-align: center">Valor Integral R$680,00</h4>
                                <v-alert type="success"> Receba R$30,00 de desconto pagando o valor integral via PIX </v-alert>
                                <h2 style="text-align: center">Selecione como deseja pagar.</h2>
                            </v-col>
                            <v-col>
                                <label for="">Forma de Pagamento</label><br>
                                <select class="input-acabana" v-model="forma_pagamento">
                                    <option value="" disabled selected>Selecione</option>
                                    <option value="PIX">PIX</option>
                                    <option value="CARTAO">CARTÃO</option>
                                </select>
                            </v-col>
                            <v-col>
                                <label for="">Tipo de Pagamento</label><br>
                                <select class="input-acabana" v-model="tipo_pagamento">
                                    <option value="" disabled selected>Selecione</option>
                                    <option value="TAXA">Taxa de Reserva (20%)</option>
                                    <option value="INTEGRAL">Valor Total</option>
                                </select>
                            </v-col>
                        </v-row>
                        <v-row v-if="tipo_pagamento == 'TAXA'" class="pagamento-opcao">
                            <v-col>
                            </v-col>
                            <v-col cols="12">
                                <table>
                                    <tr>
                                        <td>Valor da Inscrição</td>
                                        <td>R$680,00</td>
                                    </tr>
                                    <tr>
                                        <td>Taxa de Reserva</td>
                                        <td>20%</td>
                                    </tr>
                                    <tr>
                                        <td>Valor a ser pago</td>
                                        <td>R$136,00</td>
                                    </tr>
                                </table>
                                <div id="paymentBrick_container"></div>
                            </v-col>
                        </v-row>
                        <v-row v-if="tipo_pagamento == 'INTEGRAL'" class="pagamento-opcao">
                            <v-col>
                            </v-col>
                            <v-col cols="12" v-if="forma_pagamento == 'PIX'">
                                <table>
                                    <tr>
                                        <td>Valor da Inscrição</td>
                                        <td>R$680,00</td>
                                    </tr>
                                    <tr>
                                        <td>Desconto via PIX</td>
                                        <td style="color: red">- R$30,00</td>
                                    </tr>
                                    <tr>
                                        <td>Valor a ser pago</td>
                                        <td>R$650,00</td>
                                    </tr>
                                </table>
                                <div id="paymentBrick_container"></div>
                            </v-col>
                            <v-col cols="12" v-else>
                                <p>Valor a ser pago: {{ parseFloat(participante.valor).toLocaleString('pt-BR', { style:  'currency', currency: 'BRL' }) }}</p>
                                <div id="paymentBrick_container"></div>
                            </v-col>
                        </v-row>

                        <v-row v-if="pagamento_enviado">
                            <v-col>
                                <div id="statusScreenBrick_container"></div>
                            </v-col>
                        </v-row>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-if="etapa == 4" class="sucesso-inscricao">
            <v-col cols="12" style="text-align: center;">
                <h3>Parabéns!</h3>
                <h4>Sua inscrição foi realizada com sucesso!</h4>
                <p class="codigo-inscricao">Seu código de inscrição é</p>
                <div class="codigo" @click="copiar">
                    <v-icon color="#fff" dense small>
                        far fa-copy
                    </v-icon>
                    {{ participanteSucesso.codigo }}-{{ participanteSucesso.id }}
                </div>
                <small> Clique no código para copiar </small>
            </v-col>
            <v-col cols="12">
                <p style="text-align: center">
                    <strong>Atenção:</strong> Guarde este código, ele será necessário para o check-in no dia do evento.
                </p>
                <p class="texto-aviso">
                    O nosso robô do Whatsapp irá tentar colocar o numero <strong>{{ participanteSucesso.whatsapp }}</strong> em um grupo de WhatsApp de aquecimento. Caso não consiga, você receberá um link para entrar no grupo pelo seu WhatsApp.
                </p>

                <small>
                    Em caso de dúvidas ou problemas, entre em contato conosco pelo WhatsApp clicando no botão abaixo.
                </small>

                <a href="https://wa.me/5562993043851" target="_blank">
                    <button class="button-fale-conoso">FALE CONOSCO</button>
                </a>
            </v-col>
        </v-row>
        
        <RodapePage></RodapePage>

        <div class="fill" v-if="loading">
            <div class="loading">
                <img :src="logo" alt="">
            </div>
        </div>

        <button v-shortkey="['ctrl', 'alt', 'o']" @shortkey="usaValorTeste()">Open</button>

    </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import config from '@/store/config';
import moment from 'moment';
import RodapePage from '@/components/RodapePage.vue';

moment.locale('pt-br');

const api = axios.create({
    baseURL: config.baseURL
});

const mp = new MercadoPago('APP_USR-df1224e0-9694-4059-893d-aa4402ce477b', {locale: 'pt'});

async function formatarNumeroCelular(numero){
    let n = numero.replace(/\D/g, '');
    let n1 = n.slice(0, 2);
    let n2 = n.slice(2, 3);
    let n3 = n.slice(3, 7);
    let n4 = n.slice(7, 11);

    return `(${n1}) ${n2}${n3}-${n4}`;
}

export default {
    name: 'InscricaoView',
    data() {
        return {
            loading: true,
            banner: require('@/assets/img/banner.jpg'),
            logo: require('@/assets/logo.png'),
            geradoPagamento: false,
            participante: {},
            etapa: 1,
            periodoDesconto: false,
            pagamento_enviado: false,
            edicao: {},
            dadosInsere: {},
            dataEscrita: '',
            desabilitaBotao: false,
            selecionouOutra: false,
            nome: '',
            sexo: '',
            estado: '',
            cidade: '',
            nascimento: '',
            igreja: '',
            pastor: '',
            funcao: '',
            funcaoOutra: '',
            rg: '',
            orgao_expedidor: '',
            whatsapp: '',
            email: '',
            tamanho_camiseta: '',
            declaracao1: false,
            declaracao2: false,
            forma_pagamento: '',
            tipo_pagamento: '',
            participanteSucesso: {},
            valorTeste: false,
            grupo: {
                link: '',
            }
        };
    },
    components: {
        RodapePage
    },
    async mounted(){
        await this.init();
        this.loading = false;
    },
    watch: {
        funcao(){
            if(this.funcao == 'Outra'){
                this.selecionouOutra = true;
            }else{
                this.selecionouOutra = false;
            }
        },
        async tipo_pagamento(){
            if(this.forma_pagamento == ""){
                this.$swal('Atenção', 'Selecione a forma de pagamento', 'warning');
                this.tipo_pagamento = '';
                return;
            }
            await this.verificaECalculaValores();
        },
        async forma_pagamento(){
            await this.verificaECalculaValores();
        }
    },
    methods: {
        async init(){
            if(this.$route.params.id){
                await this.retornaProximaEdicao();
                try {
                    await this.sucesso(this.$route.params.id)
                } catch (error) {
                    this.$swal('Erro', 'Não foi possível encontrar sua inscrição', 'error');
                    this.$router.push(`/${this.$route.params.slug}`);
                    return; 
                }
                return
            }
            await this.retornaProximaEdicao();

            let participante = localStorage.getItem(`participante-${this.edicao.id}`);
            let dadosInsere = localStorage.getItem(`dadosInsere-${this.edicao.id}`);
            this.dadosInsere = JSON.parse(dadosInsere);
            let inserido = localStorage.getItem(`inserido-${this.edicao.id}`);
            if(participante){
                this.participante = JSON.parse(participante);
                this.$cookies.set('participante', this.participante);
                if(this.participante.id){
                    try {
                        let participanteStatus = await this.retornaParticipantePeloId(this.participante.id);
                        if(participanteStatus.status_pagamento == "CANCELADO"){
                            this.$swal('Atenção', 'Sua inscrição foi cancelada, por favor, refaça o processo', 'warning');
                            //LIMPA TODO LOCAL STORAGE
                            localStorage.removeItem(`participante-${this.edicao.id}`);
                            localStorage.removeItem(`dadosInsere-${this.edicao.id}`);
                            localStorage.removeItem(`inserido-${this.edicao.id}`);
                            this.etapa = 1;
                            return;
                        }
                    } catch (error) {
                        this.$swal('Erro', 'Não foi possível encontrar sua inscrição', 'error');
                        localStorage.removeItem(`participante-${this.edicao.id}`);
                        localStorage.removeItem(`dadosInsere-${this.edicao.id}`);
                        localStorage.removeItem(`inserido-${this.edicao.id}`);
                        this.etapa = 1;
                        return; 
                    }
                    
                }

                if(inserido == 'S'){
                    this.etapa = 3;
                }else{
                    this.etapa = 2;
                }
            }
        },
        async verificaECalculaValores(){
            if(this.tipo_pagamento == 'TAXA'){
                this.$swal('Atenção', 'O valor pago como Taxa de Reserva, em caso de desistencia não será reembolsado.', 'warning');
                if(this.forma_pagamento == 'PIX'){
                    this.participante.valor = 680
                    this.iniciaMercadoPagoBricks((parseFloat(this.participante.valor) * 0.2).toFixed(2), this.participante.nome, this.forma_pagamento);
                }else{
                    this.participante.valor = 680
                    this.iniciaMercadoPagoBricks((parseFloat(this.participante.valor) * 0.2).toFixed(2), this.participante.nome, this.forma_pagamento);
                }
            }

            if(this.tipo_pagamento == 'INTEGRAL'){
                if(this.forma_pagamento == 'PIX'){
                    this.participante.valor = 650
                    this.iniciaMercadoPagoBricks(parseFloat(this.participante.valor).toFixed(2), this.participante.nome, this.forma_pagamento);
                }else{
                    this.participante.valor = 680
                    this.iniciaMercadoPagoBricks(parseFloat(this.participante.valor).toFixed(2), this.participante.nome, this.forma_pagamento);
                }
                
            }
        },
        async iniciaMercadoPagoBricks(valor, nome, formaPagamento){
            if(window.paymentBrickController){
                window.paymentBrickController.unmount();
            }

            let paymentMethods = {};

            if(formaPagamento == 'PIX'){
                paymentMethods = {
                    bankTransfer: "all"
                };
            }

            if(formaPagamento == 'CARTAO'){
                paymentMethods = {
                    creditCard: "all",
                    maxInstallments: 12
                };
            }
            
            valor = this.valorTeste ? 0.50 : valor;
            const bricksBuilder = mp.bricks();
            const renderPaymentBrick = async (bricksBuilder) => {
                const settings = {
                    initialization: {
                    amount: valor,
                    payer: {
                        firstName: nome,
                        lastName: "",
                        email: "",
                    },
                    },
                    customization: {
                    visual: {
                        hideFormTitle: true,
                        style: {
                        theme: "dark",
                        },
                    },
                    paymentMethods
                    },
                    callbacks: {
                    onReady: () => {
                        /*
                        Callback chamado quando o Brick está pronto.
                        Aqui, você pode ocultar seu site, por exemplo.
                        */
                    },
                    onSubmit: ({ selectedPaymentMethod, formData }) => {
                        this.pagar(formData);
                    },
                    onError: (error) => {
                        console.error(error);
                    },
                    },
                };
                window.paymentBrickController = await bricksBuilder.create(
                    "payment",
                    "paymentBrick_container",
                    settings
                );
            };
            renderPaymentBrick(bricksBuilder);
        },
        async iniciaMercadoPagoStatus(id){
            if(window.paymentBrickController){
                window.paymentBrickController.unmount();
            }
            if(window.statusScreenBrickController){
                window.statusScreenBrickController.unmount();
            }
            const bricksBuilder = mp.bricks();
            const renderStatusScreenBrick = async (bricksBuilder) => {
            const settings = {
                initialization: {
                paymentId: id, // Payment identifier, from which the status will be checked
                },
                customization: {
                visual: {
                    showExternalReference: false,
                    hideStatusDetails: true,
                    hideTransactionDate: true,
                    style: {
                    theme: 'dark', // 'default' | 'dark' | 'bootstrap' | 'flat'
                    }
                },
                backUrls: {
                    'error': 'https://google.com',
                    'return': 'https://google.com'
                }
                },
                callbacks: {
                onReady: () => {
                    // Callback called when Brick is ready
                },
                onError: (error) => {
                    // Callback called for all Brick error cases
                },
                },
            };
            window.statusScreenBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
            };
            renderStatusScreenBrick(bricksBuilder);
        },
        async retornaProximaEdicao(){
            try {
                let slug = this.$route.params.slug;
                const response = await api.get(`/edicao/${slug}`);
                this.edicao = response.data;

                this.edicao.dataDescontoPagamentoAntecipado = moment(this.edicao.data_inicio).subtract(1, 'months').format('YYYY-MM-DD');
                this.edicao.dataDescontoPagamentoAntecipadoTexto = `até ${moment(this.edicao.dataDescontoPagamentoAntecipado).format('DD')} de ${(moment(this.edicao.dataDescontoPagamentoAntecipado).format('MMMM').toUpperCase())} de ${moment(this.edicao.dataDescontoPagamentoAntecipado).format('YYYY')}`; 	
                if(moment().isBefore(this.edicao.dataDescontoPagamentoAntecipado)){
                    this.periodoDesconto = true;
                }

                let dataInicio = moment(this.edicao.data_inicio).format('DD');
                let dataFim = moment(this.edicao.data_fim).format('DD');
                let mesEscritoInicio = moment(this.edicao.data_inicio).format('MMMM');
                let mesEscritoFim = moment(this.edicao.data_fim).format('MMMM');

                if(mesEscritoInicio == mesEscritoFim){
                    this.dataEscrita = `${dataInicio} a ${dataFim} de ${mesEscritoInicio} de ${moment(this.edicao.data_inicio).format('YYYY')}`;
                }else{
                    this.dataEscrita = `${dataInicio} de ${mesEscritoInicio} a ${dataFim} de ${mesEscritoFim} de ${moment(this.edicao.data_inicio).format('YYYY')}`;
                }
            } catch (error) {
                this.$swal('Erro', 'Não foi encontrada nenhuma edição para essa região.', 'error');
                setTimeout(() => {
                    window.location.href = 'https://www.instagram.com/acabana_oficial/';
                }, 4000);
            }
        },
        async corrigir(){
            this.etapa = 1;
            let participante = localStorage.getItem(`participante-${this.edicao.id}`);
            if(participante){
                this.participante = JSON.parse(participante);
                this.nome = this.participante.nome;
                this.sexo = this.participante.sexo;
                this.estado = this.participante.estado;
                this.cidade = this.participante.cidade;
                this.nascimento = moment(this.participante.nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
                this.igreja = this.participante.igreja;
                this.pastor = this.participante.pastor;
                this.funcao = this.participante.funcao;
                this.funcaoOutra = this.participante.funcaoOutra;
                this.rg = this.participante.rg;
                this.orgao_expedidor = this.participante.orgao_expedidor;
                this.whatsapp = this.participante.whatsapp;
                this.tamanho_camiseta = this.participante.tamanho_camiseta;
                this.declaracao1 = this.participante.declaracao_1 == 'S' ? true : false;
                this.declaracao2 = this.participante.declaracao_2 == 'S' ? true : false;
            }
        },
        async validarWhatsapp(){
            let n = this.whatsapp.replace(/\D/g, '');
            

            let response = await api.get(`/whatsapp/validar/55${n}`);
            if(response.data.exists){
                return true;
            }else{
                this.$swal('Atenção', 'WhatsApp inválido', 'warning');
                return false;
            }
        },
        async validarDados(){
            if(this.nome == ''){
                this.$swal('Atenção', 'Preencha o campo Nome Completo', 'warning');
                return false;
            }

            if(this.sexo == ''){
                this.$swal('Atenção', 'Selecione o campo Sexo', 'warning');
                return false;
            }

            if(this.estado == ''){
                this.$swal('Atenção', 'Selecione o campo Estado', 'warning');
                return false;
            }

            if(this.cidade == ''){
                this.$swal('Atenção', 'Preencha o campo Cidade', 'warning');
                return false;
            }

            if(this.nascimento == ''){
                this.$swal('Atenção', 'Preencha o campo Data de Nascimento', 'warning');
                return false;
            }

            let idade = moment().diff(this.nascimento, 'years');

            if(idade <= 15){
                this.$swal('Atenção', 'Idade mínima para participar é de 16 anos', 'warning');
                return false;
            }

            if(this.igreja == ''){
                this.$swal('Atenção', 'Preencha o campo Igreja', 'warning');
                return false;
            }

            if(this.pastor == ''){
                this.$swal('Atenção', 'Preencha o campo Pastor', 'warning');
                return false;
            }

            if(this.funcao == ''){
                this.$swal('Atenção', 'Selecione o campo Função', 'warning');
                return false;
            }

            if(this.funcao == 'Outra' && this.funcaoOutra == ''){
                this.$swal('Atenção', 'Preencha o campo Outra Função', 'warning');
                return false;
            }

            if(this.rg == ''){
                this.$swal('Atenção', 'Preencha o campo RG', 'warning');
                return false;
            }

            if(this.orgao_expedidor == ''){
                this.$swal('Atenção', 'Selecione o campo Orgão Expedidor', 'warning');
                return false;
            }

            if(this.whatsapp == ''){
                this.$swal('Atenção', 'Preencha o campo WhatsApp', 'warning');
                return false;
            }

            let valido = await this.validarWhatsapp();

            if(!valido){
                return false;
            }

            if(this.email == ''){
                this.$swal('Atenção', 'Preencha o campo Email', 'warning');
                return false;
            }

            if(this.tamanho_camiseta == ''){
                this.$swal('Atenção', 'Selecione o campo Tamanho Camiseta', 'warning');
                return false;
            }

            if(!this.declaracao1){
                this.$swal('Atenção', 'Aceite a Declaração 1', 'warning');
                return false;
            }

            if(!this.declaracao2){
                this.$swal('Atenção', 'Aceite a Declaração 2', 'warning');
                return false;
            }

            return true;

        },
        async continuar(){
            let validar = await this.validarDados();
            if(!validar){
                return;
            }

            this.dadosInsere = {
                id_edicao: this.edicao.id,
                nome: this.nome.trim(),
                sexo: this.sexo,
                estado: this.estado,
                cidade: this.cidade.trim(),
                nascimento: this.nascimento,
                igreja: this.igreja.trim(),
                pastor: this.pastor.trim(),
                funcao: this.funcao == 'Outra' ? this.funcaoOutra : this.funcao,
                rg: this.rg.trim(),
                orgao_expedidor: this.orgao_expedidor,
                whatsapp: this.whatsapp.replace(/\D/g, ''),
                email: this.email.trim(),
                tamanho_camiseta: this.tamanho_camiseta,
                declaracao_1: this.declaracao1 == true ? 'S' : 'N',
                declaracao_2: this.declaracao2 == true ? 'S' : 'N',
            };

            localStorage.setItem(`dadosInsere-${this.edicao.id}`, JSON.stringify(this.dadosInsere));

            this.participante = {
                nome: this.nome.trim(),
                sexo: this.sexo,
                estado: this.estado,
                cidade: this.cidade.trim(),
                nascimento: this.nascimento,
                igreja: this.igreja.trim(),
                pastor: this.pastor.trim(),
                funcao: this.funcao,
                funcaoOutra: this.funcaoOutra.trim(),
                rg: this.rg,
                orgao_expedidor: this.orgao_expedidor,
                whatsapp: this.whatsapp,
                email: this.email.trim(),
                tamanho_camiseta: this.tamanho_camiseta,
                declaracao_1: this.declaracao1 == true ? 'S' : 'N',
                declaracao_2: this.declaracao2 == true ? 'S' : 'N',
            }

            this.participante.nascimento = moment(this.participante.nascimento).format('DD/MM/YYYY');
            this.participante.whatsapp = await formatarNumeroCelular(this.participante.whatsapp);
            localStorage.setItem(`participante-${this.edicao.id}`, JSON.stringify(this.participante));

            this.$emit('insertVisit')

            this.etapa = 2;
        },
        async enviaApi(){
            this.desabilitaBotao = true;
            try {
                this.dadosInsere.tipo_cabanista = "COMUM"
                let response = await api.post('/participante', this.dadosInsere);
                this.participante.valor = response.data.valor;
                this.participante.id = response.data.id;
                this.participante.whatsapp = await formatarNumeroCelular(this.participante.whatsapp);
                localStorage.setItem(`participante-${this.edicao.id}`, JSON.stringify(response.data));
                localStorage.setItem(`inserido-${this.edicao.id}`, 'S');
                this.etapa = 3;

            } catch (error) {
                console.log(error);
                this.desabilitaBotao = false;
            }
        },
        async scrollToBottom(){
            this.$vuetify.goTo(10000);
        },
        async scrollToTop(){
            this.$vuetify.goTo(0);
        },
        async pagarComDesconto(){
            this.tipo_pagamento = 'INTEGRAL_ANTECIPADO';
            setTimeout(() => {
                this.scrollToBottom();
            }, 1000);
        },
        async pagar(dados){
            this.participante.tipo_pagamento = this.tipo_pagamento;
            this.participante.nascimento = this.dadosInsere.nascimento;
            this.participante.id_edicao = this.edicao.id;
            this.participante.whatsapp = this.participante.whatsapp.replace(/\D/g, '');
            let dadosPagamento = {
                participante: this.participante,
                pagamento: dados
            };
            console.log(dadosPagamento);

            try {
                this.pagamento_enviado = true;
                let response = await api.post('/participante/pagamento', dadosPagamento);
                console.log(response);
                this.geradoPagamento = true;
                await this.iniciaMercadoPagoStatus(response.data.participante.charge_id);
                if(response.data.mercadoPago.status == 'approved'){
                    this.$swal('Sucesso', 'Pagamento efetuado com sucesso', 'success');
                    localStorage.removeItem(`participante-${this.edicao.id}`);
                    localStorage.removeItem(`dadosInsere-${this.edicao.id}`);
                    localStorage.removeItem(`inserido-${this.edicao.id}`);
                    await this.sucesso(this.participante.id);
                }

                if(this.forma_pagamento == 'PIX'){
                    this.verificaDeTempoEmTempoPagamentoPix(response.data.participante.charge_id);
                }
            } catch (error) {
                console.log(error);
                this.$swal('Erro', 'Erro ao efetuar pagamento', 'error');
            }
        },
        async verificaDeTempoEmTempoPagamentoPix(charge_id){
            let interval = setInterval(async () => {
                try {
                    let response = await api.get(`/pagamento/${charge_id}`);
                    if(response.data.status == 'approved'){
                        this.$swal('Sucesso', 'Pagamento efetuado com sucesso', 'success');
                        localStorage.removeItem(`participante-${this.edicao.id}`);
                        localStorage.removeItem(`dadosInsere-${this.edicao.id}`);
                        localStorage.removeItem(`inserido-${this.edicao.id}`);
                        await this.iniciaMercadoPagoStatus(charge_id);
                        clearInterval(interval);
                        await this.sucesso(this.participante.id);
                    }
                } catch (error) {
                    console.log(error);
                }
            }, 5000);
        },
        async retornaParticipantePeloId(id){
            try {
                let response = await api.get(`/participante/${id}`);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async retornaLinkGrupoPeloIdEdicao(id_edicao){
            try {
                let response = await api.get(`/edicoes/grupo/${id_edicao}`);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async sucesso(id){
            let participante = await this.retornaParticipantePeloId(id);
            let grupo = await this.retornaLinkGrupoPeloIdEdicao(participante.id_edicao);
            this.$cookies.set(`participante-${participante.id}`, participante);
            this.grupo = grupo;
            this.participanteSucesso = participante;
            this.participanteSucesso.whatsapp = await formatarNumeroCelular(this.participanteSucesso.whatsapp);
            this.etapa = 4;
        },
        copiar(){
            navigator.clipboard.writeText(`${this.participanteSucesso.codigo}-${this.participanteSucesso.id}`);
            this.$swal('Sucesso', 'Código copiado para a área de transferência', 'success');
        },
        entrarGrupo(){
            window.open(this.grupo.link, '_blank');
        },
        async usaValorTeste(){
            this.valorTeste = !this.valorTeste;
        }
    }
};
</script>

<style scoped>
    #banner {
        width: 100%;
        height: auto;
    }

    #container-acabana {
        color: #ffffff;
    }

    h1{
        font-size: 2.5em;
        font-weight: 700;
        color: var(--orange);
        text-align: center;
        text-transform: uppercase;
    }

    h3{
        font-size: 1.5em;
        font-weight: 700;
        color: var(--orange);
        text-align: center;
        text-transform: uppercase;
        margin-top: -10px;
    }

    .input-acabana {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid var(--orange);
        border-radius: 5px;
        color: #fff;
        background-color: transparent;
    }

    /* INPUT PLACEHOLDER COLOR CHANGE */
    ::-webkit-input-placeholder {
        color: #ffffff33;
    }

    .declaracao1, .declaracao2 {
        display: flex;
        align-items: center;
    }  

    .declaracao1 input, .declaracao2 input {
        margin-right: 10px;
    }

    .declaracao1 label, .declaracao2 label {
        font-size: 0.8em;
        color: #fff;
    }

    /* CHECKBOX CUSTOMIZADO */
    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
    }

    input[type="checkbox"] + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid var(--orange);
        background-color: transparent;
        border-radius: 5px;
    }

    input[type="checkbox"]:checked + label:before {
        background-color: var(--orange);
    }

    input[type="checkbox"] + label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

    /* SELECT CUSTOMIZADO */

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid var(--orange);
        border-radius: 5px;
        color: var(--orange);
        background-color: transparent;
    }

    select option {
        color: var(--orange);
        background-color: #000000;
    }

    /* SELECT PLACEHOLDER COLOR CHANGE */

    select option[disabled]:first-child {
        color: var(--orange)33;
    }



    .button-acabana {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid var(--orange);
        border-radius: 5px;
        color: var(--orange);
        background-color: transparent;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 10vh;
    }

    .button-acabana:hover {
        background-color: var(--orange);
        color: #000000
    }

    .button-acabana:disabled {
        background-color: var(--orange)33;
    }

    .button-acabana:disabled:hover {
        background-color: var(--orange)33;
    }


    .button-link {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        color: var(--red-danger);
        border: 1px solid var(--red-danger);
        border-radius: 5px;
        background-color: transparent;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 10vh;
    }

    .button-link:hover {    
        background-color: var(--red-danger);
        color: #ffffff
    }

    .button-link:disabled {
        background-color: var(--orange)33;
    }

    .button-link:disabled:hover {
        background-color: var(--orange)33;
    }
    

    .dados {
        margin-top: 20px;
    }

    .dados h3 {
        font-size: 1.5em;
        font-weight: 700;
        color: var(--orange);
        text-transform: uppercase;
    }

    .dados p {
        font-size: 1.2em;
        font-weight: 700;
        color: var(--orange);
    }

    table {
        width: 100%;
    }

    table tr td {
        padding: 10px;
        border-bottom: 1px solid var(--orange);
    }

    table tr td:first-child {
        font-weight: 700;
    }

    table tr td:last-child {
        text-align: right;
    }
    

    .card-cabana {
        background-color: #000000;
        color: var(--orange);
        border: 1px solid var(--orange);
        border-radius: 5px;
    }

    .card-cabana h4 {
        font-size: 1.5em;
        font-weight: 700;
        color: var(--orange);
        text-transform: uppercase;
    }

    .card-cabana table {
        width: 100%;
        color: var(--orange);
    }

    .card-cabana table tr td {
        padding: 10px;
        border-bottom: 1px solid var(--orange);
    }
    

    .pagamento-opcao {
        margin-top: 20px;
    }

    .pagamento-opcao p {
        font-size: 1.2em;
        font-weight: 700;
        color: var(--orange);
        text-transform: uppercase;
        text-align: center;
    }


    .card-desconto {
        background-color: var(--orange);
        color: #000000;
        border: 1px solid var(--orange);
        border-radius: 5px;
    }

    .card-desconto h5 {
        font-size: 1em;
        font-weight: 700;
        color: #000000;
        text-transform: uppercase;
    }

    .card-desconto .texto-titulo {
        font-size: 1.1em;
        font-weight: 500;
        color: #000000;
    }

    .card-desconto .texto-validade {
        font-size: 1.1em;
        font-weight: 400;
        color: #000000;
    }

    .card-desconto small {
        font-size: 0.9em;
        font-weight: 400;
        color: #000000;
    }

    .button-desconto {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid #000000;
        border-radius: 5px;
        color: #000000;
        background-color: var(--orange);
        font-size: 1.1em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
    }


    .fill{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #23221bff;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    /* IMAGE BOUNCE SLOW */

    .loading img {
        display: block;
        width: 80%;
        margin: 0 auto;
        animation: bounce 2s infinite;
    }

    @media (max-width: 768px) {
        .loading img {
            width: 50%;
        }
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }


    .sucesso-inscricao h4 {
        font-size:.9em;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }

    .sucesso-inscricao .codigo-inscricao {
        margin-top: 20px;
        font-size: 1.1em;
        font-weight: 700;
        color: var(--orange);
        text-transform: uppercase;
        text-align: center;
    }

    /* CODIGO DE INSCRIÇÃO EM UMA CAIXA COM OPÇÃO DE CLICAR PARA COPIAR */
    .codigo {
        font-size: 1.5em;
        font-weight: 700;
        color: #fff;
        text-align: center;
        background-color: var(--orange);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        width: 50%;
        margin: 0 auto;
    }

    .icone {
        margin-right: 10px;
        font-weight: 200;
    }

    @media (max-width: 768px) {
        .codigo {
            width: 80%;
        }
    }

    .texto-aviso {
        font-size: 1em;
        font-weight: 400;
        color: #fff;
        text-align: center;
        margin-top: 20px;
    }



    .button-acabana-final {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid var(--orange);
        border-radius: 5px;
        color: var(--orange);
        background-color: transparent;
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 5vh;
    }

    .button-acabana-final:hover {
        background-color: var(--orange);
        color: #000000
    }

    .button-acabana-final:disabled {
        background-color: var(--orange)33;
    }

    .button-acabana-final:disabled:hover {
        background-color: var(--orange)33;
    }



    .button-fale-conoso {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        background-color: var(--orange);
        font-size: 1.2em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 2vh;
    }

    .button-fale-conoso:hover {
        background-color: #fff;
        color: var(--orange)
    }
    

</style>